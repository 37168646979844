import React from 'react'
import { useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../../../_mis/helpers'
import CustomPagination from '../../../../../_mis/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../../_mis/layout/components/searchbar/searchbar'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Select from 'react-select'
import EaButton from '../../../../../_mis/layout/components/button/eaButton';
import { Button } from 'react-bootstrap';

const Overview = () => {
    const navigate = useNavigate();
    const navigateToSupport = () => {
        navigate('/team-members/support-request-title')
    }
    return (
        <>
            {/* chart start */}
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center'>
                            {/* begin::Title */}
                            <h3 className='card-label fw-bold font-17 mb-0'>Professional Details</h3>
                            <EaButton isModal={false} className="btn-sm btn-primary my-1" btnName='Update' />
                            {/* end::Title */}
                        </div>
                        <div className='card-body pt-2'>
                            <div className='row'>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Role</label>
                                    <div>
                                        <span className='font-14 text-dark'>Engineer</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Team</label>
                                    <div>
                                        <span className='font-14 text-dark'>Flutter</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Joining Date</label>
                                    <div>
                                        <span className='font-14 text-dark'>16 Jun 2022</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Job Role Type</label>
                                    <div>
                                        <span className='font-14 text-dark'>Intern</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Work Location</label>
                                    <div>
                                        <span className='font-14 text-dark'>Office</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Total Work Experience</label>
                                    <div>
                                        <span className='font-14 text-dark'>3 Months</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-6 col-xl-4">
                                    <label className='font-14 text-muted mb-2'>Education</label>
                                    <div>
                                        <Button variant='secondary' size='sm' className='me-2'>
                                            Computer Engineer
                                        </Button>
                                        <Button variant='secondary' size='sm'>
                                            Google Flutter Cert.
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4">
                                    <label className='font-14 text-muted mb-2'>Skills</label>
                                    <div>
                                        <Button variant='secondary' size='sm' className='me-2'>
                                            Android Native
                                        </Button>
                                        <Button variant='secondary' size='sm' className='me-2'>
                                            Flutter
                                        </Button>
                                        <Button variant='secondary' size='sm' className='me-2'>
                                            React
                                        </Button>
                                        <Button variant='secondary' size='sm'>
                                            Lottie
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* chart end */}

            {/* chart start */}
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center'>
                            {/* begin::Title */}
                            <h3 className='card-label fw-bold font-17 mb-0'>Personal Details</h3>
                            <EaButton isModal={false} className="btn-sm btn-primary my-1" btnName='Update' />
                            {/* end::Title */}
                        </div>
                        <div className='card-body pt-2'>

                            <div className='row'>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Date of Birth</label>
                                    <div>
                                        <span className='font-14 text-dark'>08 Jul 1991</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Gender</label>
                                    <div>
                                        <span className='font-14 text-dark'>Male</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Merital Status</label>
                                    <div>
                                        <span className='font-14 text-dark'>Single</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* chart end */}

            {/* chart start */}
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7 align-items-center'>
                            {/* begin::Title */}
                            <h3 className='card-label fw-bold font-17 mb-0'>Communication Details</h3>
                            <EaButton isModal={false} className="btn-sm btn-primary my-1" btnName='Update' />
                            {/* end::Title */}
                        </div>
                        <div className='card-body pt-2'>
                            <div className='row'>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Phone Number</label>
                                    <div>
                                        <span className='font-14 text-dark'>(215) 424-7763</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Alternate Phone Number</label>
                                    <div>
                                        <span className='font-14 text-dark'>(818) 313-7673 - Father</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Company Email</label>
                                    <div>
                                        <span className='font-14 text-dark'>katie63@aol.com</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Personal Email</label>
                                    <div>
                                        <span className='font-14 text-dark'>Daniel_hamilton@aol.com</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Permanent Address</label>
                                    <div>
                                        <span className='font-14 text-dark'>179 Sampson Street, Georgetown, CO 80444</span>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 mb-6">
                                    <label className='font-14 text-muted mb-2'>Temporary Address</label>
                                    <div>
                                        <span className='font-14 text-dark'>605 Dog Hill Lane, Topeka, KS 66603</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* chart end */}
        </>
    )
}

export default Overview