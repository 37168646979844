import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'


export function ResetPassword() {

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      >
        {/* begin::Title */}
        <div className='text-start mb-6'>
          <h1 className='text-dark font-17 font-bold mb-1'>Set New Password</h1>
        </div>
        {/* end::Title */}


        {/* begin::Form group */}
        <div className='fv-row mb-4'>
          <label className='form-label text-dark'>New Password</label>
          <input
            type='text'
            placeholder='Type here..'
            autoComplete='off'
            className='form-control'
          />
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-4'>
          <label className='form-label text-dark'>Confirm New Password
          </label>
          <input
            type='text'
            placeholder='Type here..'
            autoComplete='off'
            className='form-control'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}


        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary fw-500 w-100'
        >
          <span className='indicator-label'>Reset Password</span>

        </button>
        {/* end::Form group */}
      </form>
    </>
  )
}
