import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_mis/helpers'
import { useNavigate } from 'react-router-dom'
import EaButton from '../../../_mis/layout/components/button/eaButton'
import EaModal from '../../../_mis/layout/components/modal/eaModal'
import CustomPagination from '../../../_mis/layout/components/pagination/pagination'
import CustomSearchBar from '../../../_mis/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../_mis/layout/core'
import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import TableTeamMembers from './components/tableTeamMembers'

const optionGender = [
    { value: 'male', label: 'Male' },
    { value: 'fmale', label: 'Female' }
]

const rolesoptions = [
    { value: 'allrole', label: 'All Roles' },
    { value: 'tl', label: 'TL' },
    { value: 'pm', label: 'PM' },
    { value: 'dm', label: 'DM' },
]

const teamoptions = [
    { value: 'allteam', label: 'All Team' },
    { value: 'ui', label: 'UI' },
    { value: 'react', label: 'React' },
    { value: 'php', label: 'PHP' },
]

const TeamMembers = () => {
    const [value, onChange] = useState(new Date());
    const navigate = useNavigate()
    const intl = useIntl()
    const headerTitle = {
        title: "Team",
        subTitle: "Team Members"
    }
    const [option, setOption] = useState('1')
    return (
        <>

            <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: 'Team Members' })}</PageTitle>
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <div><h3 className="card-title align-items-start flex-column my-0">
                                <span className="card-label fw-bold font-17">Team Analysis</span>
                            </h3>

                            </div>
                        </div>
                        <div className='card-body'>
                            Chart Area
                        </div>
                    </div>
                </div>
            </div>
            <div className='card'>
                {/* begin::Header */}
                <div className='card-header border-0 py-2 px-7 align-items-center'>
                    <CustomSearchBar />
                    <div className="ms-auto">
                        <div className='card-toolbar d-flex my-0'>

                            <Select
                                options={teamoptions}
                                isSearchable
                                placeholder="All Teams"
                                className='react-select-container my-1 me-3 w-130px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />

                            <Select
                                options={rolesoptions}
                                isSearchable
                                placeholder="All Roles"
                                className='react-select-container my-1 me-3 w-120px'
                                classNamePrefix="react-select"
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#0c78a4',
                                    },
                                })}
                            />

                            {/* <EaButton isModal={true} className="btn-sm btn-primary my-1" dataTarget='#ea_modal_create_team_member' btnName='Add Team Member' /> */}
                            <button className="btn btn-sm btn-primary my-1" onClick={() => navigate('add-teammember')}>Add Team Members</button>

                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        <TableTeamMembers />
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
            <div>
            </div>
            <div className="table-info py-5">
                <div className="row g-0">
                    <div className="col">
                        Showing 1 to 10 of 50 entries
                    </div>
                    <div className="col">
                        <CustomPagination />
                    </div>
                </div>
            </div>
            <EaModal id='ea_modal_create_team_member' modalTitle='Add Team Member' >
                {/* <Form> */}
                <div className="row">
                    <div className="mb-4 col-sm-6">
                        <label className="form-label">First Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type here..."
                        />
                    </div>
                    <div className="mb-4 col-sm-6">
                        <label className="form-label">Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type here..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-sm-6">
                        <label className="form-label">Phone Number</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type here..."
                        />
                    </div>
                    <div className="mb-4 col-sm-6">
                        <label className="form-label">Joining Date</label>
                        <DateRangePicker
                            initialSettings={
                                {
                                    startDate: '01/01/2020',
                                    endDate: '01/12/2022',
                                    alwaysShowCalendars: true,
                                    cancelClass: 'btn-secondary',
                                    singleDatePicker: true,
                                    drops: "auto",

                                    locale: {
                                        format: 'DD/MM/YYYY'
                                    },
                                }}
                        >
                            <input type="text" className="form-control calendar" autoFocus={true} />
                        </DateRangePicker>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 fv-row mb-4">
                        <label className="form-label">Date of Birth</label>
                        <DateRangePicker
                            initialSettings={
                                {
                                    startDate: '01/01/2020',
                                    endDate: '01/12/2022',
                                    alwaysShowCalendars: true,
                                    cancelClass: 'btn-secondary',
                                    singleDatePicker: true,
                                    drops: "auto",

                                    locale: {
                                        format: 'DD/MM/YYYY'
                                    },
                                }}
                        >
                            <input type="text" className="form-control calendar" autoFocus={true} />
                        </DateRangePicker>
                    </div>
                    <div className="col-lg-6 fv-row mb-4">
                        <label className="form-label">Gender</label>
                        <Select
                            options={optionGender}
                            isSearchable
                            className='react-select-container mb-4'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <label className="form-label">Company Email</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Type here..."
                    />
                </div>
                <div className="row">
                    <div className="col-lg-6 fv-row mb-4">
                        <label className="form-label">Team</label>
                        <Select
                            options={teamoptions}
                            isSearchable
                            className='react-select-container mb-4'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                    <div className="col-lg-6 fv-row mb-4">
                        <label className="form-label">Role</label>
                        <Select
                            options={rolesoptions}
                            isSearchable
                            className='react-select-container mb-4'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mb-4 col-sm-6">
                        <label className="form-label">Total Number of Experience</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type here..."
                        />
                    </div>
                    <div className="mb-4 col-sm-6">
                        <label className="form-label">Highest Education</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="react-select (multi)"
                        />
                    </div>
                </div>

            </EaModal>
        </>
    )
}

export default TeamMembers