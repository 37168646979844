import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_mis/helpers'
import EaButton from '../../../../_mis/layout/components/button/eaButton'
import CustomPagination from '../../../../_mis/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_mis/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_mis/layout/core'
import { EaStatisticsWidget } from '../../../../_mis/partials/widgets'
import TableVehicalReport from './components/tableVehicalReport'
import Select from 'react-select'

const reportingRole = [
    { value: 'role1', label: 'Role1' },
    { value: 'role2', label: 'Role2' }
]

type Props = {
    className: string
}
const RolesPermission: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Masters",
        subTitle: "Roles & Permissions"
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Roles & Permissions' })}</PageTitle>

            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        {/* <CustomSearchBar /> */}
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                {/* <EaButton isModal={false} className="btn-sm btn-primary my-1" btnName='Add Role' /> */}
                                <button className="btn btn-sm btn-primary my-1" onClick={handleShow}>Add Role</button>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className=' text-muted fw-500'>
                                        <th className='min-w-100px py-2'>Feature</th>
                                        <th className='min-w-100px py-2'>Super Admin <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </a></th>
                                        <th className='min-w-100px py-2'>HR Executive <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </a></th>
                                        <th className='min-w-100px py-2'>Project Manager <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                        </a></th>

                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td> Masters</td>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fread1' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fread1'>Read</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fwrite1' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fwrite1'>Write</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fdlt1' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fdlt1'>Delete</label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fread2' value='1' />
                                                <label className="form-check-label" htmlFor='fread2'>Read</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fwrite2' value='1' />
                                                <label className="form-check-label" htmlFor='fwrite2'>Write</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fdlt2' value='1' />
                                                <label className="form-check-label" htmlFor='fdlt2'>Delete</label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fread3' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fread3'>Read</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fwrite3' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fwrite3'>Write</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fdlt3' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fdlt3'>Delete</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> Team Members</td>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fread5' value='1' />
                                                <label className="form-check-label" htmlFor='fread5'>Read</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fwrite5' value='1' />
                                                <label className="form-check-label" htmlFor='fwrite5'>Write</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fdlt5' value='1' />
                                                <label className="form-check-label" htmlFor='fdlt5'>Delete</label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fread6' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fread6'>Read</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fwrite6' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fwrite6'>Write</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fdlt6' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fdlt6'>Delete</label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fread7' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fread7'>Read</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fwrite7' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fwrite7'>Write</label>
                                            </div>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid mb-1'>
                                                <input className='form-check-input widget-13-check me-1' type='checkbox' id='fdlt7' value='1' defaultChecked={true} />
                                                <label className="form-check-label" htmlFor='fdlt7'>Delete</label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
            </div>

            <Modal show={show} onHide={handleClose} id="ea_modal_add_role" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <label className="form-label">Role Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type here..."
                        />
                    </div>
                    <div className="">
                        <label className="form-label">Parent/Reporting Role (If available)</label>
                        <Select
                            options={reportingRole}
                            isSearchable
                            className='react-select-container'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <Button variant="secondary" onClick={handleClose} className="w-46">
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose} className="w-46">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default RolesPermission