import React from 'react'

const CustomSearchBar: React.FC = () => {
    return (
        <div className='col-12 col-md-auto mt-0'>
            <input type="text" placeholder='Search' className='form-control form-search' />
        </div>
    )
}

export default CustomSearchBar