import React from 'react'
import EaModal from '../modal/eaModal'

const ChangePassword: React.FC = () => {
    return (
        <EaModal
            modalTitle='Change Password'
            id='ea_modal_change_password'
        >
            <div className="mb-4">
                <label className="form-label">Current Password</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type here..."
                />
            </div>
            <div className="mb-4">
                <label className="form-label">New Password</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type here..."
                />
            </div>
            <div>
                <label className="form-label">Confirm Password</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Type here..."
                />
            </div>
        </EaModal>
    )
}

export default ChangePassword