import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_mis/helpers'
import EaButton from '../../../../_mis/layout/components/button/eaButton'
import CustomPagination from '../../../../_mis/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_mis/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_mis/layout/core'
import { EaStatisticsWidget } from '../../../../_mis/partials/widgets'
import TableTeamReport from './components/tableTeamReport'
import Select from 'react-select'


type Props = {
    className: string
}
const WorkLocation: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Masters",
        subTitle: "Work Location"
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Work Location' })}</PageTitle>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                {/* <EaButton isModal={false} className="btn-sm btn-primary my-1" btnName='Add Work Location' /> */}
                                <button className="btn btn-sm btn-primary my-1" onClick={handleShow}>Add Work Location</button>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px'>Work Location</th>
                                        <th className='min-w-80px text-end'>Actions</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td className=''>Office</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Home</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Hybrid</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} id="ea_modal_add_worklocation" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Work Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <label className="form-label">Work Location Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type here..."
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <Button variant="secondary" onClick={handleClose} className="w-46">
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose} className="w-46">
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WorkLocation