/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import EaButton from '../../../layout/components/button/eaButton'
import EaModal from '../../../layout/components/modal/eaModal'
import ChangePassword from '../../../layout/components/header/changePassword'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg text-dark menu-gray-900 font-13 pt-4 w-200px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-2 pt-1 pb-3'>
            <div className='symbol symbol-36px me-5'>
              <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center font-13 fw-500'>
                {currentUser?.first_name} {currentUser?.first_name}
                {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
              </div>
              <a href='javascript:void(0)' className='text-muted text-hover-primary font-13'>
                {/* {currentUser?.email} */}
                Super Admin
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-0'></div>

        <div className='menu-item'>
          <Link to={'/profile/myprofile'} className='menu-link px-5 rounded-0'>
            My Account
          </Link>
        </div>
        <div className='separator my-0'></div>
        <div className='menu-item'>
          <a href="#"
            data-bs-toggle={'modal'}
            data-bs-target={'#ea_modal_change_password'} className='menu-link px-5 rounded-0'
          >
            Change Password
          </a>
          {/* <Link to={''} data-bs-toggle={'modal'}
            data-bs-target={'#ea_modal_change_password'} className='menu-link px-5 rounded-0'>
            Change Password
          </Link> */}
        </div>

        <div className='separator my-0'></div>

        <div className='menu-item'>
          <a onClick={logout} className='menu-link px-5 rounded-0'>
            Sign Out
          </a>
        </div>
      </div>

    </>
  )
}

export { HeaderUserMenu }
