import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../../_mis/helpers'

let shoeEditBtn = true
const TeamMemberNameHeader: React.FC = () => {
    const location = useLocation()

    return (
        <div className='card mb-4 mb-xl-5'>
            <div className='card-body pt-5 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap'>
                    <div className='flex-grow-1'>

                        <div className='d-flex flex-wrap flex-sm-nowrap'>
                            <div className='me-3 me-md-5 mb-3'>
                                <div className='symbol symbol-40px symbol-lg-160px symbol-fixed'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Eafruits' />
                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-1'>
                                            <span className='text-dark font-20 fw-bold me-1'>
                                                Dennis Callis
                                            </span>

                                        </div>

                                        <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                                            <span
                                                className='d-flex align-items-center me-6 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/communication/com006.svg'
                                                    className='me-1'
                                                />
                                                Intern
                                            </span>
                                            <span
                                                className='d-flex align-items-center me-6 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen017.svg'
                                                    className='me-1'
                                                />
                                                Engineer - Flutter
                                            </span>
                                            <span
                                                className='d-flex align-items-center me-5 mb-2 '
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen018.svg'
                                                    className='me-1'
                                                />
                                                Working From Office
                                            </span>
                                        </div>
                                        <div className='d-flex flex-wrap mb-2 pe-2 font-14 text-muted'>
                                            <span
                                                className='d-flex align-items-center me-6 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/communication/com002.svg'
                                                    className='me-1'
                                                />
                                                rodger913@aol.com
                                            </span>
                                            <span
                                                className='d-flex align-items-center me-6 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/electronics/elc002.svg'
                                                    className='me-1'
                                                />
                                                (708) 813-8989
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a href="#" className="btn btn-secondary">Active</a>

                    </div>
                </div>

                <div className='d-flex overflow-auto h-40px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-500 flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-0 ` +
                                    (location.pathname === '/team-member/team-member-name/overview' && 'active')
                                }
                                to='/team-member/team-member-name/overview'
                            >
                                Overview
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-0 ` +
                                    (location.pathname === '#' && 'active')
                                }
                                to='#'
                            >
                                Performance
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-0 ` +
                                    (location.pathname === '#' && 'active')
                                }
                                to='#'
                            >
                                Documents
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-0 ` +
                                    (location.pathname === '#' && 'active')
                                }
                                to='#'
                            >
                                Projects
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-0 ` +
                                    (location.pathname === '#' && 'active')
                                }
                                to='#'
                            >
                                Leaves
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TeamMemberNameHeader
