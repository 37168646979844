import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_mis/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_mis/assets/ts/_utils'
import CreditIssueReports from '../pages/masters/jobRoleType/jobRoleType'
import TeamReport from '../pages/masters/workLocation/workLocation'
import VehicalReport from '../pages/masters/rolesPermission/rolesPermission'
import ComparisionReport from '../pages/masters/comparisionReport/comparisionReport'
import RejectionReport from '../pages/masters/rejectionReport/rejectionReport'
import DailyStockInReport from '../pages/masters/dailyStockInReport/dailyStockInReport'
import DailyStockOutReport from '../pages/masters/dailyStockInReport/dailyStockOutReport'
import TeamMembers from '../pages/teamMembers/teamMembers'
import { DashboardWrapper } from '../pages/dashboard/dashboardWrapper'
import TeamMemberName from '../pages/teamMembers/teamMemberName/teamMemberName'
import Teams from '../pages/masters/teams/teams'
import JobRoleType from '../pages/masters/jobRoleType/jobRoleType'
import WorkLocation from '../pages/masters/workLocation/workLocation'
import RolesPermission from '../pages/masters/rolesPermission/rolesPermission'
import AddteamMembers from '../pages/teamMembers/addTeammember'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UserPage = lazy(() => import('../modules/users/UserPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} /> {/*  DASHBOARD */}

        {/* <Route path='team-members/*'> 
          <Route index element={<TeamMembers />} />
          <Route path='team-members-name/*' element={<TeamMemberName />} />
          <Route path='support-request-title/*' element={<SupportRequestTitle />} />
          <Route path='survey-name' element={<TeleSurveyName />} />
          <Route path='*' element={<Navigate to="/team-members-name" />} />
        </Route> */}

        <Route path='team-members/*' element={<TeamMembers />} /> {/*  Teammembers PAGE */}
        <Route path='team-members/add-teammember' element={<AddteamMembers />} /> {/*  Teammembers PAGE */}
        <Route path='team-members/team-member-name/*' element={<TeamMemberName />} /> {/*  Teammembers /team-member-name */}


        <Route path='masters/*'> {/* Super admin REPORTS WITH SUBMENU */}
          <Route path='teams' element={<Teams className='teams' />} />
          <Route path='job-role-type' element={<JobRoleType className='teams' />} />
          <Route path='work-location' element={<WorkLocation className='teams' />} />
          <Route path='roles-permission' element={<RolesPermission className='teams' />} />
          <Route path='*' element={<Navigate to="/masters/teams" />} />
        </Route>

        {/* Lazy Modules */}
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/users/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='master/creditscore/*'
          element={
            <SuspensedView>
             <MasterPage />
             </SuspensedView>
          }
        /> */}

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
