import React from 'react'

const CustomPagination: React.FC = () => {
    return (
        <ul className="pagination justify-content-md-end">
            <li className="page-item previous disabled">
                <a href="#" className="page-link">
                    <i className="previous"></i>
                </a>
            </li>
            <li className="page-item offset active">
                <a href="#" className="page-link">
                    1
                </a>
            </li>
            <li className="page-item offset ">
                <a href="#" className="page-link">
                    2
                </a>
            </li>
            <li className="page-item offset">
                <a href="#" className="page-link">
                    3
                </a>
            </li>
            <li className="page-item offset">
                <a href="#" className="page-link">
                    4
                </a>
            </li>
            <li className="page-item offset">
                <a href="#" className="page-link">
                    5
                </a>
            </li>
            <li className="page-item offset">
                <a href="#" className="page-link">
                    6
                </a>
            </li>
            <li className="page-item next">
                <a href="#" className="page-link">
                    <i className="next"></i>
                </a>
            </li>
        </ul>
    )
}

export default CustomPagination