import React from 'react'
import { useNavigate } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../../_mis/helpers'

const TableTeamMembers: React.FC = () => {
    const navigate = useNavigate();
    const navigateToTeam = () => {
        navigate('/team-members/team-member-name')
    }
    return (
        <>
            <table className='table table-hover table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                {/* begin::Table head */}
                <thead>
                    <tr className='text-muted fw-500'>
                        <th className='min-w-150px'>Name</th>
                        <th className='min-w-140px'>Phone Number</th>
                        <th className='min-w-120px'>Email</th>
                        <th className='min-w-120px'>Role</th>
                        <th className='min-w-120px'>Team</th>
                        <th className='min-w-80px action-th py-2'>Actions</th>
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    <tr onClick={navigateToTeam} className='cursor-pointer'>
                        <td>
                            <div className="d-flex align-items-center">
                                {/* <span className="rounded-circle bg-light-warning pro-icon me-1 d-inline-block text-dark font-10">AS</span> */}
                                <div className='symbol symbol-40px symbol-lg-50px symbol-fixed me-2'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' className='rounded-circle' />
                                </div>
                                <div className="media-body">
                                    <p className="m-0 xs-font">Artem Sazonov</p>
                                </div>
                            </div>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                (484) 712-9639
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                gicimel@kecvanu.gov
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                CEO
                            </span>

                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                React
                            </span>

                        </td>
                        <td className='text-end'>
                            <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                            </a>
                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex align-items-center">
                                <div className='symbol symbol-40px symbol-lg-50px symbol-fixed me-2'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' className='rounded-circle' />
                                </div>
                                <div className="media-body">
                                    <p className="m-0 xs-font">Artem Sazonov</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                (484) 712-9639
                            </span>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                gicimel@kecvanu.gov
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                CTO
                            </span>

                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Design
                            </span>

                        </td>
                        <td className='text-end'>
                            <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                            </a>
                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex align-items-center">
                                <div className='symbol symbol-40px symbol-lg-50px symbol-fixed me-2'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' className='rounded-circle' />
                                </div>
                                <div className="media-body">
                                    <p className="m-0 xs-font">Artem Sazonov</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                (484) 712-9639
                            </span>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                gicimel@kecvanu.gov
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Project Manager
                            </span>

                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Full Stack
                            </span>

                        </td>
                        <td className='text-end'>
                            <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                            </a>
                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex align-items-center">
                                <div className='symbol symbol-40px symbol-lg-50px symbol-fixed me-2'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' className='rounded-circle' />
                                </div>
                                <div className="media-body">
                                    <p className="m-0 xs-font">Artem Sazonov</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                (484) 712-9639
                            </span>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                gicimel@kecvanu.gov
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                BDM
                            </span>

                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Flutter
                            </span>

                        </td>
                        <td className='text-end'>
                            <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                            </a>
                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex align-items-center">
                                <div className='symbol symbol-40px symbol-lg-50px symbol-fixed me-2'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' className='rounded-circle' />
                                </div>
                                <div className="media-body">
                                    <p className="m-0 xs-font">Artem Sazonov</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                (484) 712-9639
                            </span>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                gicimel@kecvanu.gov
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Sr. Engineer
                            </span>

                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Mean Stack
                            </span>

                        </td>
                        <td className='text-end'>
                            <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                            </a>
                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex align-items-center">
                                <div className='symbol symbol-40px symbol-lg-50px symbol-fixed me-2'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' className='rounded-circle' />
                                </div>
                                <div className="media-body">
                                    <p className="m-0 xs-font">Artem Sazonov</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                (484) 712-9639
                            </span>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                gicimel@kecvanu.gov
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Intern
                            </span>

                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Business Analysts
                            </span>

                        </td>
                        <td className='text-end'>
                            <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                            </a>
                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex align-items-center">
                                <div className='symbol symbol-40px symbol-lg-50px symbol-fixed me-2'>
                                    <img src={toAbsoluteUrl('/media/avatars/img_user.png')} alt='Mis Moweb' className='rounded-circle' />
                                </div>
                                <div className="media-body">
                                    <p className="m-0 xs-font">Artem Sazonov</p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                (484) 712-9639
                            </span>
                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                gicimel@kecvanu.gov
                            </span>
                        </td>

                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                Team Leader
                            </span>

                        </td>
                        <td>
                            <span className='text-dark d-inline-block mb-1'>
                                iOS
                            </span>

                        </td>
                        <td className='text-end'>
                            <a
                                href='javascript:void(0)'
                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                            </a>
                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                            </a>
                        </td>
                    </tr>

                </tbody>
                {/* end::Table body */}
            </table>
        </>
    )
}

export default TableTeamMembers