/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_mis/helpers'
import { PageTitle } from '../../../_mis/layout/core'
import { SADashboard } from './SADashboard';


const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const headerTitle = {
    title: "Dashboard"
  }
  return (
    <>
      <PageTitle breadcrumbs={[headerTitle]} >{intl.formatMessage({ id: 'Dashboard' })}</PageTitle>
      <SADashboard />
    </>
  )
}

export { DashboardWrapper }

