import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_mis/helpers'
import EaButton from '../../../../_mis/layout/components/button/eaButton'
import CustomPagination from '../../../../_mis/layout/components/pagination/pagination'
import CustomSearchBar from '../../../../_mis/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../../_mis/layout/core'
import { EaStatisticsWidget } from '../../../../_mis/partials/widgets'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import Select from 'react-select'
import EaModal from '../../../../_mis/layout/components/modal/eaModal'

const teamLead = [
    { value: 'person1', label: 'Person1' },
    { value: 'person2', label: 'Person2' }
]

type Props = {
    className: string
}
const Teams: React.FC<Props> = ({ className }) => {
    const intl = useIntl()
    const reportHeaderTitle = {
        title: "Masters",
        subTitle: "Teams"
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <PageTitle breadcrumbs={[reportHeaderTitle]} >{intl.formatMessage({ id: 'Team' })}</PageTitle>
            <div>
                <div className='card'>
                    {/* begin::Header */}
                    <div className='card-header border-0 py-2 px-7 align-items-center'>
                        <CustomSearchBar />
                        <div className="ms-auto">
                            <div className='card-toolbar d-flex my-0'>
                                {/* <EaButton isModal={true} dataTarget='#ea_modal_add_team' className="btn-sm btn-primary my-1" btnName='Add Team' /> */}
                                {/* <button className="btn btn-sm btn-primary my-1" data-toggle="modal" data-target="#ea_modal_add_team">Add Team</button> */}
                                <button className="btn btn-sm btn-primary my-1" onClick={handleShow}>Add Team</button>
                            </div>
                        </div>
                    </div>
                    {/* end::Header */}
                    {/* begin::Body */}
                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-2'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='text-muted fw-500'>
                                        <th className='min-w-150px'>Team Name</th>
                                        <th className='min-w-140px'>Members</th>
                                        <th className='min-w-120px'>Team Lead</th>
                                        <th className='min-w-80px text-end'>Actions</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    <tr>
                                        <td className=''>Design</td>
                                        <td className=''>14 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Flutter</td>
                                        <td className=''>8 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>PHP</td>
                                        <td className=''>5 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>React</td>
                                        <td className=''>6 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Mean Stack</td>
                                        <td className=''>10 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Full Stack</td>
                                        <td className=''>6 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>QA/Testers</td>
                                        <td className=''>8 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=''>Business Analysts</td>
                                        <td className=''>12 Members</td>
                                        <td className=''>Daniel Hamilton</td>
                                        <td className='text-end'>
                                            <a
                                                href='javascript:void(0)'
                                                className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm me-3'
                                            >
                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='ic mr-0' />
                                            </a>
                                            <a href='javascript:void(0)' className='btn py-0 ps-1 pe-0 text-hover-primary btn-sm'>
                                                <KTSVG path='/media/icons/duotune/arrows/arr011.svg' className='ic mr-0' />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                {/* end::Table body */}
                            </table>
                            {/* end::Table */}
                        </div>
                        {/* end::Table container */}
                    </div>
                    {/* begin::Body */}
                </div>
                <div className="table-info py-5">
                    <div className="row g-0">
                        <div className="col">
                            Showing 1 to 10 of 50 entries
                        </div>
                        <div className="col">
                            <CustomPagination />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} id="ea_modal_add_team" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">
                        <label className="form-label">Team Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Type here..."
                        />
                    </div>
                    <div className="">
                        <label className="form-label">Team Lead</label>
                        <Select
                            options={teamLead}
                            isSearchable
                            className='react-select-container'
                            classNamePrefix="react-select"
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#0c78a4',
                                },
                            })}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <Button variant="secondary" onClick={handleClose} className="w-46">
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleClose} className="w-46">
                        Create Team
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <div className="modal fade" id="ea_modal_add_team" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-dialog-centered mw-470px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modal title</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>Modal body text goes here.</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

        </>
    )
}

export default Teams