import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_mis/helpers';
import { EaChartsWidget, EaMapWidget, EaStatisticsWidget } from '../../../_mis/partials/widgets';
import SmartSuggestions from './components/smartSuggestions';
import TopCustomers from './components/topCustomers';
import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import TopWarehouses from './components/topWarehouses';
import Toasts from '../../../_mis/layout/components/toast/toast';
// import YearMonthPicker from 'react-year-month-picker'
const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate1', label: 'Chocolate1' },
    { value: 'strawberry1', label: 'Strawberry1' },
    { value: 'vanilla1', label: 'Vanilla1' },
    { value: 'chocolate2', label: 'Chocolate2' },
    { value: 'strawberry2', label: 'Strawberry2' },
    { value: 'vanilla2', label: 'Vanilla2' },
    { value: 'chocolate3', label: 'Chocolate3' },
    { value: 'strawberry3', label: 'Strawberry3' },
    { value: 'vanilla3', label: 'Vanilla3' }
]
const optgroup = [
    {
        label: "Group 1",
        options: [
            { label: "Group 1 option 1", value: "value1" },
            { label: "Group 1 option 2", value: "value2" }
        ]
    },
    {
        label: "Group 2",
        options: [
            { label: "Group 2 option 1", value: "value3" },
            { label: "Group 2 option 2", value: "value4" }
        ]
    },
    { label: "A root option", value: "value5" },
    { label: "Another root option", value: "value6" }
];
const handleOnchange = (e: any) => {
}
export const SADashboard = () => {
    return (
        <>
            {/* begin::Row */}
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <h3 className='card-title align-items-start flex-column my-0'>
                                <span className='card-label fw-bold font-17 mb-1'>Card 1:1</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <div className='card-body'>
                            Card body
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}
            <div className='row'>
                <div className='col-xl-6'>
                    {/* <EaMapWidget className='card-xl-stretch mb-5' /> */}
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <h3 className='card-title align-items-start flex-column my-0'>
                                <span className='card-label fw-bold font-17 mb-1'>Card 1:2</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <div className='card-body'>
                            Card body
                        </div>
                    </div>
                </div>
                <div className='col-xl-6'>
                    {/* <SmartSuggestions className='card-xl-stretch mb-5' /> */}
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <h3 className='card-title align-items-start flex-column my-0'>
                                <span className='card-label fw-bold font-17 mb-1'>Card 1:2</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <div className='card-body'>
                            Card body
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Row */}

            {/* begin::Row */}
            <div className='row'>
                <div className='col-xl-4'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <h3 className='card-title align-items-start flex-column my-0'>
                                <span className='card-label fw-bold font-17 mb-1'>Card 1:3</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <div className='card-body'>
                            Card body
                        </div>
                    </div>
                </div>
                <div className='col-xl-4'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <h3 className='card-title align-items-start flex-column my-0'>
                                <span className='card-label fw-bold font-17 mb-1'>Card 1:3</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <div className='card-body'>
                            Card body
                        </div>
                    </div>
                </div>
                <div className='col-xl-4'>
                    <div className='card card-xl-stretch mb-5'>
                        <div className='card-header border-0 py-3 py-md-2 px-5 px-md-7'>
                            {/* begin::Title */}
                            <h3 className='card-title align-items-start flex-column my-0'>
                                <span className='card-label fw-bold font-17 mb-1'>Card 1:3</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <div className='card-body'>
                            Card body
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}

            {/* end::Row */}



        </>
    )
}