import React from 'react'
import { KTSVG } from '../../../helpers'
type modalProps = {
    children: any,
    modalTitle: string,
    id: string,
    actionBtnName?: string,
    widthcls?: string
}
const EaModal: React.FC<modalProps> = ({ children, modalTitle, id, actionBtnName, widthcls }) => {
    return (
        <div className='modal fade' id={id} aria-hidden='true' data-bs-backdrop="static" data-bs-keyboard="false">
            <div className=
                {widthcls ? `${widthcls} modal-dialog modal-dialog-centered` : 'modal-dialog modal-dialog-centered mw-470px'}
            >
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h2 className='modal-title fw-bold'>{modalTitle}</h2>
                        <div className='btn p-1 mh-24 btn-active-light-primary ms-2' data-bs-dismiss='modal'>
                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='me-0 ic mr-0' />
                        </div>
                    </div>
                    <div className='modal-body px-md-12'>
                        {children}
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">
                            {actionBtnName ? actionBtnName : "Submit"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EaModal