import React from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../../_mis/layout/core'
import Overview from './components/overview'
import TeamMemberNameHeader from './components/teamMemberNameHeader'

const TeamMemberName: React.FC = () => {
    const intl = useIntl()
    const teleSalesHeaderTitle = {
        title: "Team",
        subTitle: "Team Members"
    }

    return (
        <>
            <PageTitle breadcrumbs={[teleSalesHeaderTitle]} >{intl.formatMessage({ id: 'Team Members' })}</PageTitle>
            <TeamMemberNameHeader />
            <Routes>
                <Route path='overview' element={<Overview />} />
                <Route index element={<Navigate to="/team-members/team-member-name/overview" />} />
            </Routes>
        </>
    )
}

export default TeamMemberName