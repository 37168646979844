import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_mis/helpers'
import { useNavigate } from 'react-router-dom'
import EaButton from '../../../_mis/layout/components/button/eaButton'
import EaModal from '../../../_mis/layout/components/modal/eaModal'
import CustomPagination from '../../../_mis/layout/components/pagination/pagination'
import CustomSearchBar from '../../../_mis/layout/components/searchbar/searchbar'
import { PageTitle } from '../../../_mis/layout/core'
import Select from 'react-select'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import TableTeamMembers from './components/tableTeamMembers'
import moment from 'moment'

const optionGender = [
    { value: 'male', label: 'Male' },
    { value: 'fmale', label: 'Female' }
]

const rolesoptions = [
    { value: 'allrole', label: 'All Roles' },
    { value: 'tl', label: 'TL' },
    { value: 'pm', label: 'PM' },
    { value: 'dm', label: 'DM' },
]

const teamoptions = [
    { value: 'allteam', label: 'All Team' },
    { value: 'ui', label: 'UI' },
    { value: 'react', label: 'React' },
    { value: 'php', label: 'PHP' },
]

const jobrole = [
    { value: 'alljobrole', label: 'All Job Role' },
    { value: 'option1', label: 'Option1' },
    { value: 'option2', label: 'Option2' },
    { value: 'option3', label: 'Option3' },
]

const locationoptions = [
    { value: 'alllocation', label: 'All Location' },
    { value: 'option1', label: 'Option1' },
    { value: 'option2', label: 'Option2' },
    { value: 'option3', label: 'Option3' },
]

const AddteamMembers = () => {
    const [value, onChange] = useState(new Date());
    const navigate = useNavigate()
    const intl = useIntl()
    const headerTitle = {
        title: "Team",
        subTitle: "Team Members"
    }
    const [option, setOption] = useState('1')
    return (
        <>

            <PageTitle breadcrumbs={[headerTitle]}>{intl.formatMessage({ id: 'Team Members' })}</PageTitle>
            <div className='row mt-3 mt-md-0'>
                <div className='col-12 mt-md-0'>

                    <div className='card'>
                        {/* begin::Header */}
                        <div className='card-header border-0 py-2 px-7 align-items-center'>
                            <div><h3 className="card-title align-items-start flex-column my-0">
                                <span className="card-label fw-bold font-17">Add Team Member</span>
                            </h3>

                            </div>
                        </div>
                        {/* end::Header */}
                        {/* begin::Body */}
                        <div className='card-body py-3'>
                            <div className="row">
                                <div className="mb-4 col-sm-6">
                                    <label className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here..."
                                    />
                                </div>
                                <div className="mb-4 col-sm-6">
                                    <label className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-4 col-sm-6">
                                    <label className="form-label">Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here..."
                                    />
                                </div>
                                <div className="mb-4 col-sm-6">
                                    <label className="form-label">Joining Date</label>
                                    <DateRangePicker
                                        initialSettings={
                                            {
                                                startDate: '01/01/2020',
                                                endDate: '01/12/2022',
                                                alwaysShowCalendars: true,
                                                cancelClass: 'btn-secondary',
                                                singleDatePicker: true,
                                                drops: "auto",

                                                locale: {
                                                    format: 'DD/MM/YYYY'
                                                },
                                            }}
                                    >
                                        <input type="text" className="form-control calendar" autoFocus={true} />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Date of Birth</label>
                                    <DateRangePicker
                                        initialSettings={
                                            {
                                                startDate: '01/01/2020',
                                                endDate: '01/12/2022',
                                                alwaysShowCalendars: true,
                                                cancelClass: 'btn-secondary',
                                                singleDatePicker: true,
                                                drops: "auto",

                                                locale: {
                                                    format: 'DD/MM/YYYY'
                                                },
                                            }}
                                    >
                                        <input type="text" className="form-control calendar" autoFocus={true} />
                                    </DateRangePicker>
                                </div>
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Gender</label>
                                    <Select
                                        options={optionGender}
                                        isSearchable
                                        className='react-select-container'
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#0c78a4',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Personal Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Company Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="row">
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Team</label>
                                    <Select
                                        options={teamoptions}
                                        isSearchable
                                        className='react-select-container'
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#0c78a4',
                                            },
                                        })}
                                    />
                                </div>
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Role</label>
                                    <Select
                                        options={rolesoptions}
                                        isSearchable
                                        className='react-select-container'
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#0c78a4',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Job Role Type</label>
                                    <Select
                                        options={jobrole}
                                        isSearchable
                                        className='react-select-container'
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#0c78a4',
                                            },
                                        })}
                                    />
                                </div>
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Work Location</label>
                                    <Select
                                        options={locationoptions}
                                        isSearchable
                                        isMulti
                                        className='react-select-container'
                                        classNamePrefix="react-select"
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#0c78a4',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <label className="form-label">Total Number of Experience</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here..."
                                    />
                                </div>
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Last Working Day</label>
                                    <DateRangePicker
                                        initialSettings={
                                            {
                                                startDate: '01/01/2020',
                                                endDate: '01/12/2022',
                                                alwaysShowCalendars: true,
                                                cancelClass: 'btn-secondary',
                                                singleDatePicker: true,
                                                drops: "auto",

                                                locale: {
                                                    format: 'DD/MM/YYYY'
                                                },
                                            }}
                                    >
                                        <input type="text" className="form-control calendar" autoFocus={true} />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <label className="form-label">Highest Education</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="react-select (multi)"
                                    />
                                </div>
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Working Period</label>
                                    <DateRangePicker
                                        initialSettings={
                                            {
                                                startDate: '01/01/2020',
                                                endDate: '01/12/2020',
                                                alwaysShowCalendars: true,
                                                cancelClass: 'btn-secondary',
                                                drops: "auto",
                                                locale: {
                                                    format: 'DD/MM/YYYY'
                                                },
                                                ranges: {
                                                    'Today': [moment(), moment()],
                                                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                                },
                                            }}
                                    >
                                        <input type="text" className="form-control calendar" />
                                    </DateRangePicker>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <div className="">
                                        <label className="form-label me-2" >Profile
                                        </label> <label htmlFor='profile-lic' className='form-control file-input'>
                                            <span className='text-muted'>Browse</span>
                                        </label>
                                        <input
                                            type="file" id='profile-lic'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 fv-row mb-4">
                                    <label className="form-label">Status</label>
                                    <div className="form-check form-switch form-check-custom form-check-solid">
                                        <label className="form-check-label me-3">
                                            Access Status
                                        </label>
                                        <input className="form-check-input" name="is_active" type="checkbox"
                                            value="" />
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mb-4">
                                    <label className="form-label">Marital Status</label>
                                    <div className="d-flex align-items-center">

                                        <div className="form-check form-check-custom form-check-solid form-check-sm me-4">
                                            <input className="form-check-input" type="radio" value="" name="flexRadioDefault" id="flexRadiomarried" />
                                            <label className="form-check-label" htmlFor="flexRadiomarried">
                                                Married
                                            </label>
                                        </div>

                                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                                            <input className="form-check-input" type="radio" value="" name="flexRadioDefault" id="flexRadiounmarried" />
                                            <label className="form-check-label" htmlFor="flexRadiounmarried">
                                                Unmarried
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-sm-6 mb-4">
                                    <label className='form-label'>Show Your Interests</label>
                                    <div className="d-flex align-items-center">
                                        <div>

                                            <div className='form-check form-check-sm form-check-custom form-check-solid me-4'>
                                                <input className='form-check-input widget-13-check me-2' type='checkbox'
                                                    value='1' id='check1' />
                                                <label htmlFor='check1'>Coding</label>
                                            </div>
                                        </div>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid me-4'>
                                            <input className='form-check-input widget-13-check me-2' type='checkbox'
                                                value='1' id='check2' />
                                            <label htmlFor='check2'>Music</label>
                                        </div>

                                        <div className='form-check form-check-sm form-check-custom form-check-solid me-4'>
                                            <input className='form-check-input widget-13-check me-2' type='checkbox'
                                                value='1' id='check3' />
                                            <label htmlFor='check3'>Reading</label>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="form-label">Short Description (Optional)</label>
                                <textarea className="form-control" placeholder="Type here..."></textarea>
                            </div>
                        </div>
                        {/* begin::Body */}
                        <div className="card-footer py-2 text-center">
                            <button
                                type='button'
                                className='btn btn-secondary me-3'
                            >
                                Cancel
                            </button>
                            <button
                                className='btn btn-primary'
                            >
                                Submit
                            </button>
                        </div>

                    </div>



                </div>
            </div>



        </>
    )
}

export default AddteamMembers