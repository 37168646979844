import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl();
  return (
    <>

      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/team-members'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Team Members'
      />
      <AsideMenuItemWithSub
        to='/masters'
        title='Masters'
        icon='/media/icons/duotune/abstract/abs030.svg'
      >

        <AsideMenuItem to='/masters/teams' title='Teams' hasBullet={true} />
        <AsideMenuItem to='/masters/job-role-type' title='Job Role Type' hasBullet={true} />
        <AsideMenuItem to='/masters/work-location' title='Work Location' hasBullet={true} />
        <AsideMenuItem to='/masters/roles-permission' title='Roles & Permissions' hasBullet={true} />

      </AsideMenuItemWithSub>

    </>

  )
}
